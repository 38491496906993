import React from 'react'
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import style from 'assets/jss/styles/pages/mainPageStyle.js'
import { makeStyles } from '@material-ui/core/styles'
import Img from 'gatsby-image';

const useStyles = makeStyles(style)

export const RightSection = ({ image, alt, text, title }) => {
  const classes = useStyles();
  return (
    <GridContainer className={classes.container}>
      <GridItem xs={12} sm={5} md={5} className={classes.mlAuto + " " + classes.middle}>
        <Img
          fluid={image}
          alt={alt}
          title={alt}
        />
      </GridItem>
      <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
        <h3 className={classes.info + " " + classes.titleSection}>
          {title}
        </h3>
        <p className={classes.info}>
          {text}
        </p>
      </GridItem>
    </GridContainer>
  )
} 

export const LeftSection = ({ image, alt, text, title }) => {
  const classes = useStyles();
  return (
    <GridContainer className={classes.container}>    
      <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
        <h3 className={classes.info + " " + classes.titleSection}>
          {title}
        </h3>
        <p className={classes.info}>
          {text}
        </p>
      </GridItem>
      <GridItem xs={12} sm={5} md={5} className={classes.mrAuto + " " + classes.middle}>
        <Img
          fluid={image}
          alt={alt}
          title={alt}
        />
      </GridItem>
    </GridContainer>
  )
}