import React from 'react'
import classNames from 'classnames'
import Layout from 'layout/layout'
import { graphql } from "gatsby"
import { Link } from 'gatsby'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Parallax from 'components/Parallax/Parallax.js'
import landingPageStyle from 'assets/jss/styles/pages/landingPageStyle.js'
import { makeStyles } from '@material-ui/core/styles'
import LandingServices from 'pages-sections/landing/LandingServices'
// import SectionContact from 'pages-sections/SectionContact'
// import SectionWorks from 'pages-sections/SectionWorks'
// import SectionServicesLanding from 'pages-sections/landing/SectionServicesLanding'
import { RightSection, LeftSection } from 'components/SectionsTextPic/Sections';
import InstInd from "assets/img/sections/nave-industrial.jpg";

const useStyles = makeStyles(landingPageStyle);

const Industrial = ({ data }) => {
  const classes = useStyles()

  return (
    <Layout
      pageTitle="⚡ Suburled - Instalaciones eléctricas industriales"
      pageDescription="✨ Somos especialistas en instalaciones eléctricas industriales, desde la CT hasta el acondicionamiento interior y oficinas. Nos ocupamos de todo el proceso de tu nave, proyecto, legalización, electricidad, detección de incendios y datos. Llámanos y pregunta por nuestros servicios."
      headerImage={InstInd}
      filter="dark"
      content={
        <React.Fragment>
          <h1 className={classes.typist}>Instalaciones eléctricas industriales</h1>

          <Link to="/presupuesto">
            <Button color={'primary'} className={classes.navButton}>
              Solicita Presupuesto
            </Button>
          </Link>

          <br />
        </React.Fragment>
      }
    >
      <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.mrBottom
          )}
        >
        <div dangerouslySetInnerHTML={{__html: data.industrialJson.content.childMarkdownRemark.html}} />
      </GridItem>

      {data.industrialJson.gallery.map((obj, i) => {
        if (i % 2 == 0) {
          return (
            <LeftSection
              key={i}
              classes={classes} 
              image={obj.image.childImageSharp.fluid}
              alt={obj.alt}
              text={obj.text}
              title={obj.title}
            />
          )
        } else return (
          <RightSection 
            key={i}
            classes={classes} 
            image={obj.image.childImageSharp.fluid}
            alt={obj.alt}
            text={obj.text}
            title={obj.title}
          />
        )
      })}
      <LandingServices classes={classes} />

    </Layout>
  )  
}

export default Industrial

export const query = graphql`
  query IndustrialpageQuery {
    industrialJson {
      content {
        childMarkdownRemark {
          html
        }
      }
      gallery {
        title
        text
        alt
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;